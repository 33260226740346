import React from 'react';
import '../index.css';


function Footer() {

    let year = new Date().getFullYear().toString();

    return(
        <footer>
        <div className='bg-blue-500 p-2 shadow-md'>
            <ul className='flex justify-center items-center p-2 gap-2 text-white'>
                <li><a href="https://www.closingclientsgroup.com/ccg-privacy-policy" className=' hover:underline'>Privacy Policy</a></li>
                <li><a href="https://www.closingclientsgroup.com/ccg-refund-policy" className=' hover:underline'>Refund Policy</a></li>
            </ul>
            <div className='flex justify-center items-center p-2 font-bold'>
                <p className='text-white'>© {year} Closing Clients Group</p>
            </div>   
        </div>   
    </footer>
    )
}

export default Footer;