import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../index.css';

function Navbar() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        axios.get('/api/verify-token')
            .then(res => {
                if (res.status === 200) {
                    setIsAuthenticated(true);
                }
            })
            .catch(err => console.error(err));
    }, []);

    const handleSignOut = async () => {

        let data = await axios.get('/api/signout')

        if(data.status === 200){
            setIsAuthenticated(false)
            

            window.location.href = '/login'
        }
    
    };

    return(
        <div className="navbar bg-base-100">
            <div className="flex-1">
                <a className="btn btn-ghost text-xl" href="/">Closing Clients Group</a>
            </div>
            <div className="flex-none">
                <ul className="menu menu-horizontal px-1">
                    {isAuthenticated ? (
                        window.location.pathname === '/dashboard' || window.location.pathname === '/' ? (
                            <li>
                                <button onClick={handleSignOut} className="btn bg-blue-500 text-white hover:bg-blue-600">Sign Out</button>
                            </li>
                        ) : (
                            <li>
                                <a role="button" className="btn bg-blue-500 text-white hover:bg-blue-600" href='/dashboard'>Dashboard</a>
                            </li>
                        )
                    ) : (
                        <li>
                            <a role="button" className="btn bg-blue-500 text-white hover:bg-blue-600" href='/login'>Login</a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Navbar;