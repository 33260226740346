import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const NotFound = () => {
  return (
    <>
      <Navbar />
      <div className='flex flex-col justify-center items-center h-screen'>
        <h1 className='text-3xl mb-4'>This Page Does Not Exist</h1>
        <Link to="/" className="btn bg-blue-500 hover:bg-blue-600 text-white">Go Back Home</Link>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;