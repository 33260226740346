import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import Home from './pages/Home';
import Refresh from './pages/Refresh';
import Return from './pages/Return';
import Main from "./pages/Main";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import EmailVerification from "./pages/EmailVerification";
import Dashboard from "./pages/Dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/connect/stripe",
    element: <Home />,
  },
  {
    path: "/refresh/:connectedAccountId",
    element: <Refresh />,
  },
  {
    path: "/return/:connectedAccountId",
    element: <Return />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/email-verification",
    element: <EmailVerification />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,

  },
  {
    path: "*",
    element: <NotFound />,
  },
]);




export default function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}