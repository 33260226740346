import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../index.css';

const Main = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        axios.get('/api/verify-token')
            .then(res => {
                if (res.status === 200) {
                    setIsAuthenticated(true);
                }
            })
            .catch(err => console.error(err));
    }, []);

    return(
        <>
        <Navbar />

            <div className='min-h-screen flex justify-center items-center flex-col gap-5'>
            <img src="./ccgroup.png" className='h-40' alt='logo' />  
            <a href={isAuthenticated ? "/dashboard" : "/login"} className='text-white font-bold'>
                <button type="button" className='btn bg-blue-500 p-3 rounded-lg w-40 hover:bg-blue-600 text-white'>
                    {isAuthenticated ? "Dashboard" : "Login"}
                </button> 
            </a>
            </div>

        <Footer />
        </>
    )
}

export default Main;