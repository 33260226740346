import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useForm } from "react-hook-form";
import React from 'react';

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
       
        fetch('/api/login', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(data),
        })

        window.location.href = '/email-verification';
    }

    return (
        <>
        <Navbar />
         <div className='min-h-screen flex justify-center items-center flex-col gap-5'>
            <img src="./ccgroup.png" className='h-40' alt='logo' />
            <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                {errors.email && <span className="text-red-500 text-center">This field is required</span>}
                <label className="input input-bordered flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70 text-blue-600"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
                    <input type="email" className="grow" placeholder="Email" name="email" {...register("email", {required: true})}/>
                </label>
              

                <button className="btn" type="submit">Get Magic Link</button>

            </form>
           
        </div>
        <Footer />
        </>
       
    )
}

export default Login;