import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const EmailVerification = () => {
    return (
        <>
        <Navbar />
         <div className='min-h-screen flex justify-center items-center flex-col gap-5'>
            <img src="./ccgroup.png" className='h-40' alt='logo' />
            <div className='text-center'>
                <h2 className='text-2xl font-bold text-gray-700'>If you have a valid email we will send you an email with your magic link</h2>
                <p className='text-gray-500'>Can't find it? Don't forget to check your spam inbox</p>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default EmailVerification;